<script setup lang="ts">
const props = defineProps<{
  title?: string;
  link?: string;
}>();

const { getShareLink } = usePostShare();

const onButtonClick = (
  type: "messenger" | "whatsapp" | "facebook" | "email" | "sms"
) => {
  if (props.link) {
    const shareLink = getShareLink(type, props.link, props.title);
    window.open(shareLink, "_blank");
  }
};
</script>

<template>
  <div class="post-share-social flex items-end justify-stretch">
    <div
      class="post-share-social__wrapper grow flex items-center justify-between gap-[0.625rem]"
    >
      <AppBtn
        variant="social"
        @click="onButtonClick('messenger')"
        title="Udostępnij w Messenger"
        :icon="true"
      >
        <LogoMessenger class="w-6 h-6" />
        <span class="sr-only">Udostępnij w Messenger</span>
      </AppBtn>

      <AppBtn
        variant="social"
        @click="onButtonClick('whatsapp')"
        title="Udostępnij w WhatsApp"
        :icon="true"
      >
        <LogoWhatsapp class="w-6 h-6" />
        <span class="sr-only">Udostępnij w WhatsApp</span>
      </AppBtn>

      <AppBtn
        variant="social"
        @click="onButtonClick('facebook')"
        title="Udostępnij w Facebook"
        :icon="true"
      >
        <LogoFacebook class="w-6 h-6" />
        <span class="sr-only">Udostępnij w Facebook</span>
      </AppBtn>

      <AppBtn
        variant="social"
        @click="onButtonClick('email')"
        title="Udostępnij za pomocą email"
        :icon="true"
      >
        <Icon name="ns:mail" class="w-6 h-6" />
        <span class="sr-only">Udostępnij za pomocą email</span>
      </AppBtn>

      <AppBtn
        variant="social"
        @click="onButtonClick('sms')"
        title="Udostępnij za pomocą SMS"
        :icon="true"
      >
        <Icon name="ns:message-circle" class="w-6 h-6" />
        <span class="sr-only">Udostępnij za pomocą SMS</span>
      </AppBtn>
    </div>
  </div>
</template>
