<script setup lang="ts">
const props = defineProps<{
  link?: string;
}>();

const link = computed(() => props.link);

const { copy, isSupported, copied } = useClipboard({ legacy: true });

const onButtonClick = () => {
  if (props.link) copy(props.link);
};
</script>

<template>
  <div class="post-share-link">
    <div class="post-share-link__wrapper flex flex-col items-stretch gap-6">
      <AppBtn
        @click="onButtonClick"
        variant="solid"
        :disabled="!isSupported || !link"
      >
        <span v-if="copied">Skopiowano!</span>
        <span v-else>Skopiuj link</span>
        <Icon name="ns:copy" />
      </AppBtn>
    </div>
  </div>
</template>
