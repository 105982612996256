<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4 3.45C18.15 1.2 15.15 0 12 0C5.4 0 0 5.4 0 12C0 14.1 0.600009 16.2 1.65001 18L0 24L6.30002 22.35C8.10002 23.25 10.05 23.85 12 23.85C18.6 23.85 24 18.45 24 11.85C24 8.7 22.65 5.7 20.4 3.45ZM12 21.9C10.2 21.9 8.40001 21.45 6.90001 20.55L6.59999 20.4L2.84999 21.45L3.90001 17.85L3.59999 17.4C2.54999 15.75 2.09999 13.95 2.09999 12.15C2.09999 6.75 6.6 2.25 12 2.25C14.7 2.25 17.1 3.3 19.05 5.1C21 7.05 21.9 9.45 21.9 12.15C21.9 17.4 17.55 21.9 12 21.9ZM17.4 14.4C17.1 14.25 15.6 13.5 15.3 13.5C15 13.35 14.85 13.35 14.7 13.65C14.55 13.95 13.95 14.55 13.8 14.85C13.65 15 13.5 15 13.2 15C12.9 14.85 12 14.55 10.8 13.5C9.90002 12.75 9.30001 11.7 9.15001 11.4C9.00001 11.1 9.15002 10.95 9.30002 10.8C9.45002 10.65 9.6 10.5 9.75 10.35C9.9 10.2 9.90002 10.05 10.05 9.9C10.2 9.75 10.05 9.6 10.05 9.45C10.05 9.3 9.45001 7.8 9.15001 7.2C9.00001 6.75 8.70002 6.75 8.55002 6.75C8.40002 6.75 8.24998 6.75 7.94998 6.75C7.79998 6.75 7.49998 6.75 7.19998 7.05C6.89998 7.35 6.15001 8.1 6.15001 9.6C6.15001 11.1 7.19999 12.45 7.34999 12.75C7.49999 12.9 9.44998 16.05 12.45 17.25C15 18.3 15.45 18 16.05 18C16.65 18 17.85 17.25 18 16.65C18.3 15.9 18.3 15.3 18.15 15.3C18 14.55 17.7 14.55 17.4 14.4Z"
      fill="#25D366"
    />
  </svg>
</template>
