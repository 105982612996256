<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.37257 0 0 4.97459 0 11.1111C0 14.6078 1.74499 17.7267 4.47205 19.7635V24L8.55794 21.7576C9.64837 22.0593 10.8036 22.2222 12 22.2222C18.6274 22.2222 24 17.2476 24 11.1111C24 4.97459 18.6274 0 12 0ZM13.1925 14.963L10.1366 11.7037L4.17391 14.963L10.7329 8L13.8634 11.2593L19.7516 8L13.1925 14.963Z"
      fill="#0084FF"
    />
  </svg>
</template>
